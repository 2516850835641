var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colfert-spa-block"},[_c('p',{staticClass:"block-tile"},[_vm._v("\n    "+_vm._s(_vm.$t(
        _vm.footerCenterBlockData &&
          _vm.footerCenterBlockData['left-column'] &&
          _vm.footerCenterBlockData['left-column'].title
          ? _vm.footerCenterBlockData['left-column'].title
          : ''
      ))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"colfert-spa-block-inner"},[_c('div',{staticClass:"register-office"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.footerCenterBlockData &&
          _vm.footerCenterBlockData['left-column'] &&
          _vm.footerCenterBlockData['left-column'].text
            ? _vm.footerCenterBlockData['left-column'].text
            : ''
        )}}),_vm._v(" "),_c('div',{staticClass:"register-office-img-wrap"},[_c('nuxt-img',{staticClass:"register-office-img desktop-img",attrs:{"src":_vm.footerCenterBlockData &&
            _vm.footerCenterBlockData['left-column'] &&
            _vm.footerCenterBlockData['left-column'].image &&
            _vm.footerCenterBlockData['left-column'].image.desktop
              ? _vm.footerCenterBlockData['left-column'].image.desktop
              : '/icons/placeholder.svg',"alt":_vm.footerCenterBlockData &&
            _vm.footerCenterBlockData['left-column'] &&
            _vm.footerCenterBlockData['left-column'].image &&
            _vm.footerCenterBlockData['left-column'].image.alt
              ? _vm.footerCenterBlockData['left-column'].image.alt
              : ''}})],1)]),_vm._v(" "),_c('div',{staticClass:"register-office-detail"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.footerCenterBlockData &&
          _vm.footerCenterBlockData['central-column'] &&
          _vm.footerCenterBlockData['central-column'].text
            ? _vm.footerCenterBlockData['central-column'].text
            : ''
        )}})]),_vm._v(" "),_c('div',{staticClass:"register-office-address"},[_c('div',{staticClass:"register-office-address-inner"},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.footerCenterBlockData &&
            _vm.footerCenterBlockData['right-column'] &&
            _vm.footerCenterBlockData['right-column'].text
              ? _vm.footerCenterBlockData['right-column'].text
              : ''
          )}})]),_vm._v(" "),_c('nuxt-img',{staticClass:"register-office-img mobile-img",attrs:{"src":_vm.footerCenterBlockData &&
          _vm.footerCenterBlockData['left-column'] &&
          _vm.footerCenterBlockData['left-column'].image &&
          _vm.footerCenterBlockData['left-column'].image.mobile
            ? _vm.footerCenterBlockData['left-column'].image.mobile
            : '/footer/register-office-logo.svg',"alt":_vm.footerCenterBlockData &&
          _vm.footerCenterBlockData['left-column'] &&
          _vm.footerCenterBlockData['left-column'].image &&
          _vm.footerCenterBlockData['left-column'].image.alt
            ? _vm.footerCenterBlockData['left-column'].image.alt
            : 'register office logo'}}),_vm._v(" "),_c('div',{staticClass:"social-icons"},_vm._l((_vm.socialItems),function(item,index){return _c('SfLink',{key:((item.key) + "-" + index),attrs:{"link":item.url}},[_c('nuxt-img',{staticClass:"social-icons-single icons-facebbok",attrs:{"src":item.logo,"alt":item.alt}})],1)}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }