








import { useFetch, defineComponent } from '@nuxtjs/composition-api';
import TopFooter from '~/components/Footer/TopFooter.vue';
import MiddleFooter from '~/components/Footer/MiddleFooter.vue';
import CopyrightFooter from '~/components/Footer/CopyrightFooter.vue';
import { useMenu } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'AppFooter',
  components: {
    TopFooter,
    MiddleFooter,
    CopyrightFooter,
  },
  setup() {
    const { menu: footerMenu, search: searchFooterMenu } =
      useMenu('footerMenu');
    useFetch(async () => {
      await searchFooterMenu({ code: 'footer-menu' });
    });
    return {
      footerMenu,
    };
  },
});
