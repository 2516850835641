<template>
  <div class="colfert-spa-block">
    <p class="block-tile">
      {{
        $t(
          footerCenterBlockData &&
            footerCenterBlockData['left-column'] &&
            footerCenterBlockData['left-column'].title
            ? footerCenterBlockData['left-column'].title
            : ''
        )
      }}
    </p>
    <div class="colfert-spa-block-inner">
      <div class="register-office">
        <div
          v-html="
            footerCenterBlockData &&
            footerCenterBlockData['left-column'] &&
            footerCenterBlockData['left-column'].text
              ? footerCenterBlockData['left-column'].text
              : ''
          "
        ></div>
        <div class="register-office-img-wrap">
          <nuxt-img
            class="register-office-img desktop-img"
            :src="
              footerCenterBlockData &&
              footerCenterBlockData['left-column'] &&
              footerCenterBlockData['left-column'].image &&
              footerCenterBlockData['left-column'].image.desktop
                ? footerCenterBlockData['left-column'].image.desktop
                : '/icons/placeholder.svg'
            "
            :alt="
              footerCenterBlockData &&
              footerCenterBlockData['left-column'] &&
              footerCenterBlockData['left-column'].image &&
              footerCenterBlockData['left-column'].image.alt
                ? footerCenterBlockData['left-column'].image.alt
                : ''
            "
          />
        </div>
      </div>
      <div class="register-office-detail">
        <div
          v-html="
            footerCenterBlockData &&
            footerCenterBlockData['central-column'] &&
            footerCenterBlockData['central-column'].text
              ? footerCenterBlockData['central-column'].text
              : ''
          "
        ></div>
      </div>
      <div class="register-office-address">
        <div class="register-office-address-inner">
          <div
            v-html="
              footerCenterBlockData &&
              footerCenterBlockData['right-column'] &&
              footerCenterBlockData['right-column'].text
                ? footerCenterBlockData['right-column'].text
                : ''
            "
          ></div>
        </div>
        <nuxt-img
          class="register-office-img mobile-img"
          :src="
            footerCenterBlockData &&
            footerCenterBlockData['left-column'] &&
            footerCenterBlockData['left-column'].image &&
            footerCenterBlockData['left-column'].image.mobile
              ? footerCenterBlockData['left-column'].image.mobile
              : '/footer/register-office-logo.svg'
          "
          :alt="
            footerCenterBlockData &&
            footerCenterBlockData['left-column'] &&
            footerCenterBlockData['left-column'].image &&
            footerCenterBlockData['left-column'].image.alt
              ? footerCenterBlockData['left-column'].image.alt
              : 'register office logo'
          "
        />
        <div class="social-icons">
          <SfLink
            v-for="(item, index) in socialItems"
            :key="`${item.key}-${index}`"
            :link="item.url"
          >
            <nuxt-img
              class="social-icons-single icons-facebbok"
              :src="item.logo"
              :alt="item.alt"
            />
          </SfLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'MiddleFooterCenterBlock',
  components: {
    SfLink,
  },
  props: {
    footerCenterBlockData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const socialItems = ref(
      Object.entries(props.footerCenterBlockData?.['right-column'] || {})
        ?.filter(
          ([key, value]) =>
            key?.startsWith('social-logo-') &&
            value?.alt &&
            value.logo &&
            value.url
        )
        ?.map(([key, value]) => ({
          key,
          alt: value.alt,
          logo: value.logo,
          url: value.url,
        })) || []
    );

    return {
      socialItems,
    };
  },
});
</script>
