<template>
  <div class="discover-colfert-blok">
    <p class="discover-colfert-title">
      {{
        $t(
          footerRightBlockData &&
            footerRightBlockData['left-column'] &&
            footerRightBlockData['left-column'].title
            ? footerRightBlockData['left-column'].title
            : ''
        )
      }}
    </p>
    <div class="discover-colfert-blok-inner">
      <div class="discover-colfert-left">
        <nuxt-img
          class="footer-yco-img"
          :src="
            footerRightBlockData &&
            footerRightBlockData['left-column'] &&
            footerRightBlockData['left-column']['left-logo'] &&
            footerRightBlockData['left-column']['left-logo'].logo
              ? footerRightBlockData['left-column']['left-logo'].logo
              : '/icons/placeholder.svg'
          "
          :alt="
            footerRightBlockData &&
            footerRightBlockData['left-column'] &&
            footerRightBlockData['left-column']['left-logo'] &&
            footerRightBlockData['left-column']['left-logo'].alt
              ? footerRightBlockData['left-column']['left-logo'].alt
              : ''
          "
        />
        <p class="discover-colfert-title-bottom">
          {{
            $t(
              footerRightBlockData &&
                footerRightBlockData['left-column'] &&
                footerRightBlockData['left-column'].subtitle
                ? footerRightBlockData['left-column'].subtitle
                : ''
            )
          }}
        </p>
      </div>
      <div class="discover-colfert-right">
        <nuxt-img
          class="yed-img"
          :src="
            footerRightBlockData &&
            footerRightBlockData['right-column'] &&
            footerRightBlockData['right-column']['right-logo'] &&
            footerRightBlockData['right-column']['right-logo'].logo
              ? footerRightBlockData['right-column']['right-logo'].logo
              : '/icons/placeholder.svg'
          "
          :alt="
            footerRightBlockData &&
            footerRightBlockData['right-column'] &&
            footerRightBlockData['right-column']['right-logo'] &&
            footerRightBlockData['right-column']['right-logo'].alt
              ? footerRightBlockData['right-column']['right-logo'].alt
              : ''
          "
        />
        <p class="door-professional-text">
          {{
            $t(
              footerRightBlockData &&
                footerRightBlockData['right-column'] &&
                footerRightBlockData['right-column'].subtitle
                ? footerRightBlockData['right-column'].subtitle
                : ''
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MiddleFooterCenterBlock',
  props: {
    footerRightBlockData: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
