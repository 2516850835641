<template>
  <div class="copyright-footer">
    <div class="container">
      <p class="copyright-text">
        <nuxt-link
          v-for="(child, childIndex) in subFooterMenu.items"
          :key="`${childIndex}-${child.label}`"
          :to="localePath(child.link)"
        >
          {{ $t(child.label) }}
        </nuxt-link>
      </p>
    </div>
  </div>
</template>

<script>
import { useFetch, defineComponent } from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';

export default defineComponent({
  setup() {
    const { menu: subFooterMenu, search: searchSubFooterMenu } =
      useMenu('subFooterMenu');
    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length);
    useFetch(async () => {
      await searchSubFooterMenu({ code: 'sub-footer-menu' });
    });
    return {
      subFooterMenu,
      hasChildren,
    };
  },
});
</script>

<style scoped lang="scss">
.copyright-footer {
  padding-top: 1.5rem;
  background-color: var(--c-grey);
  padding-bottom: 1.25rem;
  .copyright-text {
    text-align: center;
    a {
      color: var(--c-dark-grey);
      font-family: var(--lato-regular);
      font-size: var(--line-height-16);
      font-weight: var(--font-weight-regular);
      line-height: var(--line-height-22);
      position: var(--relative);
      display: inline-block;
      @include to-mobile-max {
        display: var(--block);
        font-size: var(--line-height-13);
        line-height: var(--line-height-16);
        text-align: left;
        margin-bottom: 0.9375rem;
      }
      &:not(:last-child) {
        margin-right: 1.25rem;
        &:after {
          content: '';
          position: var(--absolute);
          top: 50%;
          right: -0.75rem;
          width: 0.3125rem;
          height: 0.125rem;
          background-color: var(--c-dark-grey);
          @include to-mobile-max {
            display: var(--hidden);
          }
        }
      }
    }
  }
}
</style>
