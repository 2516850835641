var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discover-colfert-blok"},[_c('p',{staticClass:"discover-colfert-title"},[_vm._v("\n    "+_vm._s(_vm.$t(
        _vm.footerRightBlockData &&
          _vm.footerRightBlockData['left-column'] &&
          _vm.footerRightBlockData['left-column'].title
          ? _vm.footerRightBlockData['left-column'].title
          : ''
      ))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"discover-colfert-blok-inner"},[_c('div',{staticClass:"discover-colfert-left"},[_c('nuxt-img',{staticClass:"footer-yco-img",attrs:{"src":_vm.footerRightBlockData &&
          _vm.footerRightBlockData['left-column'] &&
          _vm.footerRightBlockData['left-column']['left-logo'] &&
          _vm.footerRightBlockData['left-column']['left-logo'].logo
            ? _vm.footerRightBlockData['left-column']['left-logo'].logo
            : '/icons/placeholder.svg',"alt":_vm.footerRightBlockData &&
          _vm.footerRightBlockData['left-column'] &&
          _vm.footerRightBlockData['left-column']['left-logo'] &&
          _vm.footerRightBlockData['left-column']['left-logo'].alt
            ? _vm.footerRightBlockData['left-column']['left-logo'].alt
            : ''}}),_vm._v(" "),_c('p',{staticClass:"discover-colfert-title-bottom"},[_vm._v("\n        "+_vm._s(_vm.$t(
            _vm.footerRightBlockData &&
              _vm.footerRightBlockData['left-column'] &&
              _vm.footerRightBlockData['left-column'].subtitle
              ? _vm.footerRightBlockData['left-column'].subtitle
              : ''
          ))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"discover-colfert-right"},[_c('nuxt-img',{staticClass:"yed-img",attrs:{"src":_vm.footerRightBlockData &&
          _vm.footerRightBlockData['right-column'] &&
          _vm.footerRightBlockData['right-column']['right-logo'] &&
          _vm.footerRightBlockData['right-column']['right-logo'].logo
            ? _vm.footerRightBlockData['right-column']['right-logo'].logo
            : '/icons/placeholder.svg',"alt":_vm.footerRightBlockData &&
          _vm.footerRightBlockData['right-column'] &&
          _vm.footerRightBlockData['right-column']['right-logo'] &&
          _vm.footerRightBlockData['right-column']['right-logo'].alt
            ? _vm.footerRightBlockData['right-column']['right-logo'].alt
            : ''}}),_vm._v(" "),_c('p',{staticClass:"door-professional-text"},[_vm._v("\n        "+_vm._s(_vm.$t(
            _vm.footerRightBlockData &&
              _vm.footerRightBlockData['right-column'] &&
              _vm.footerRightBlockData['right-column'].subtitle
              ? _vm.footerRightBlockData['right-column'].subtitle
              : ''
          ))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }