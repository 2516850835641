




































import { SfFooter, SfList, SfMenuItem, SfLink } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfFooter,
    SfList,
    SfMenuItem,
    SfLink,
  },
  props: {
    footerMenu: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length);
    return {
      addBasePath,
      hasChildren,
    };
  },
  mounted() {
    this.openAccordionsFaq();
  },
  methods: {
    openAccordionsFaq() {
      const bannerGroupCollapseElements =
        document.querySelectorAll('.group-title');
      bannerGroupCollapseElements.forEach((bannerGroupCollapse) => {
        bannerGroupCollapse.addEventListener('click', () => {
          const { parentElement } = bannerGroupCollapse;
          if (parentElement.classList.contains('active')) {
            parentElement.classList.remove('active');
          } else {
            parentElement.classList.add('active');
          }
        });
      });
    },
  },
});
