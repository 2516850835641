<template>
  <div
    class="middle-footer"
    v-if="footerWhiteBlockContent || footerWhiteContentStatus"
  >
    <div class="container">
      <div class="middle-footer-inner">
        <div class="footer-logo">
          <nuxt-img
            class="footer-logo-img"
            :src="footerWhiteLogoDesktop"
            :alt="footerWhiteLogoAlt"
          />
        </div>
        <MiddleFooterCenterBlock
          :footer-center-block-data="footerCenterBlockData"
        />
        <MiddleFooterRightBlock
          :footer-right-block-data="footerRightBlockData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import MiddleFooterCenterBlock from '~/components/Footer/MiddleFooterCenterBlock.vue';
import MiddleFooterRightBlock from '~/components/Footer/MiddleFooterRightBlock.vue';

export default defineComponent({
  name: 'MiddleFooter',
  components: {
    MiddleFooterCenterBlock,
    MiddleFooterRightBlock,
  },
  props: {
    imageHeight: {
      type: [Number, String],
      default: 35,
    },
    imageWidth: {
      type: [Number, String],
      default: 34,
    },
  },
  setup() {
    const { locale } = useI18n();
    const { blocks, loading, loadBlocks } = useContent(
      `footer-white-row-${locale.includes('it') ? 'it' : 'en'}`
    );
    const footerWhiteRowBlockSelector = `footer-white-row-${
      locale.includes('it') ? 'it' : 'en'
    }`;
    const footerWhiteBlockContent = ref('');
    const footerWhiteContentStatus = ref('');
    const footerWhiteLogoDesktop = ref('');
    const footerWhiteLogoAlt = ref('');
    const footerRightBlockData = ref();
    const footerCenterBlockData = ref();
    useFetch(async () => {
      await loadBlocks({ identifiers: [footerWhiteRowBlockSelector] });
      try {
        const blockData = contentGetters.getCmsBlockContent(blocks.value);
        footerWhiteBlockContent.value = blockData[footerWhiteRowBlockSelector];
        footerWhiteContentStatus.value = footerWhiteBlockContent.value?.active;
        footerWhiteLogoDesktop.value =
          footerWhiteBlockContent.value?.data?.['left-block']?.image?.desktop ||
          '';
        footerWhiteLogoAlt.value =
          footerWhiteBlockContent.value?.data?.['left-block']?.image?.alt || '';
        footerCenterBlockData.value =
          footerWhiteBlockContent.value?.data?.['central-block'];
        footerRightBlockData.value =
          footerWhiteBlockContent.value?.data?.['right-block'];
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });
    return {
      footerWhiteBlockContent,
      loading,
      footerWhiteContentStatus,
      footerWhiteLogoAlt,
      footerWhiteLogoDesktop,
      footerCenterBlockData,
      footerRightBlockData,
    };
  },
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.middle-footer {
  padding-top: 4rem;
  padding-bottom: 3rem;
  @include to-mobile-max {
    padding-top: 3.0625rem;
  }
  .desktop-img {
    @include to-mobile-max {
      display: var(--hidden);
    }
  }
  .mobile-img {
    display: var(--hidden);
    @include to-mobile-max {
      display: var(--block);
    }
  }
  .middle-footer-inner {
    display: var(--flex);
    flex-wrap: var(--flex-wrap);
    p {
      margin-bottom: 0;
    }
    .footer-logo {
      width: 16.666667%;
      @include to-tablet-1500 {
        width: 13.666667%;
      }
      @include to-tablet-max {
        width: 100%;
      }
      @include to-mobile-max {
        width: 100%;
        display: var(--hidden);
      }
      .footer-logo-img {
        max-width: 11.8125rem;
        margin-right: 1.25rem;
        img {
          max-width: 11.8125rem;
          max-height: 6.5rem;
        }
        @include to-tablet-1500 {
          max-width: 8.75rem;
        }
        img {
          width: 100%;
          width: 9.375rem;
        }
      }
    }
    .colfert-spa-block {
      width: 50.000001%;
      @include to-tablet-1500 {
        width: 53.000001%;
      }
      @include to-tablet-max {
        width: 100%;
        margin-top: 1.875rem;
      }
      @include to-mobile-max {
        width: 100%;
        margin-top: 0;
      }
      border-left: 0.0625rem solid var(--c-yellow);
      padding-left: 0.75rem;
      .block-tile {
        color: #000;
        font-family: var(--lato-regular);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-20);
        padding-bottom: 1.25rem;
        @include to-mobile-max {
          font-size: var(--font-size-12);
          line-height: var(--line-height-16);
          padding-bottom: 1rem;
        }
      }
      .colfert-spa-block-inner {
        display: var(--flex);
        @include to-mobile-max {
          flex-wrap: var(--flex-wrap);
        }
        .register-office,
        .register-office-detail,
        .register-office-address {
          width: 33.33%;
          padding-right: 0.9375rem;
          @include to-mobile-max {
            width: 100%;
            padding-bottom: 1rem;
            padding-right: 0;
          }
        }
        .register-office {
          font-family: var(--lato-regular);
          font-size: var(--font-size-16);
          line-height: var(--line-height-22);
          color: var(--c-black);
          @include to-mobile-max {
            font-size: var(--font-size-12);
            line-height: var(--line-height-16);
          }
          .register-office-img-wrap {
            padding-top: 1.375rem;
            line-height: 0;
            img {
              max-width: 12.75rem;
            }
            @include to-mobile-max {
              display: var(--hidden);
            }
          }
        }
        .register-office-detail {
          font-family: var(--lato-regular);
          font-size: var(--font-size-16);
          line-height: var(--line-height-22);
          color: var(--c-black);
          @include to-mobile-max {
            font-size: var(--font-size-12);
            line-height: var(--line-height-16);
          }
        }
        .register-office-address {
          @include to-mobile-max {
            padding-bottom: 0;
          }
          .register-office-address-inner,
          .register-office-address-inner a {
            font-family: var(--lato-regular);
            font-size: var(--font-size-16);
            line-height: var(--line-height-22);
            color: var(--c-black);
            @include to-mobile-max {
              font-size: var(--font-size-12);
              line-height: var(--line-height-18);
            }
          }
          .register-office-address-inner {
            @include to-mobile-max {
              padding-bottom: 2.5rem;
            }
          }
          .social-icons {
            padding-top: 0.625rem;
            @include to-mobile-max {
              padding-top: 2rem;
            }
            a {
              display: inline-block;
            }
            .social-icons-single {
              margin-right: 0.5rem;
              max-height: 1.9375rem;
              max-width: 1.9375rem;
              border-radius: 1.9375rem;
            }
          }
        }
      }
    }
    .discover-colfert-blok {
      width: 33.333334%;
      border-left: 0.0625rem solid var(--c-yellow);
      padding-left: 0.75rem;
      @include to-tablet-max {
        width: 100%;
        margin-top: 1.875rem;
      }
      @include to-mobile-max {
        width: 100%;
        margin-top: 3.25rem;
      }
      .discover-colfert-blok-inner {
        display: var(--flex);
        flex-wrap: var(--flex-wrap);
      }
      .discover-colfert-title {
        font-family: var(--lato-regular);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-20);
        color: var(--c-black);
        padding-bottom: 1.625rem;
        width: var(--w-full);
        @include to-mobile-max {
          font-size: var(--font-size-12);
          line-height: var(--line-height-16);
        }
      }
      .discover-colfert-left {
        width: 50%;
        @include to-tablet-max {
          width: 33.33%;
        }
        @include to-mobile-max {
          width: 100%;
        }
        .footer-yco-img {
          max-width: 9.625rem;
          img {
            max-width: 9.625rem;
            max-height: 4.4375rem;
          }
          @include to-tablet-1500 {
            max-width: 7.5rem;
          }
        }
        .discover-colfert-title-bottom {
          font-family: var(--lato-regular);
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-medium);
          line-height: var(--line-height-12);
          color: var(--c-black);
          padding-top: 1.25rem;
          @include to-mobile-max {
            font-size: var(--font-size-12);
            line-height: var(--line-height-16);
          }
        }
      }
      .discover-colfert-right {
        width: 50%;
        @include to-tablet-max {
          width: 33.33%;
        }
        @include to-mobile-max {
          width: 100%;
          margin-top: 2.25rem;
        }
        .yed-img {
          max-width: 12rem;
          img {
            max-width: 12rem;
            max-height: 5.25rem;
          }
          @include to-tablet-1500 {
            max-width: 9.375rem;
          }
        }
        .door-professional-text {
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-medium);
          line-height: var(--line-height-12);
          color: var(--c-black);
          padding-top: 1rem;
          @include to-mobile-max {
            font-size: var(--font-size-12);
            line-height: var(--line-height-16);
          }
        }
      }
    }
  }
}
</style>
